import camelCase from 'lodash/camelCase';

export default function getQueryField(
  prefix: string,
  hasLocalNews: boolean,
  byPath: boolean,
) {
  const queryField = hasLocalNews
    ? `${camelCase(prefix)}NewsArticle${byPath ? 'ByPath' : ''}`
    : 'newsArticle';
  return queryField;
}
